export const borderRadius = {
	xs: 4,
	sm: 6,
	md: 9,
	lg: 12,
	xl: 15,
};

export const navbarHeight = 57;

export const tabsHeight = 70;

export const sidebarWidth = 240;
