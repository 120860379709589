import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/welcome-email";

const emailNotifications = {
	sendWelcomeEmailToCustomer: (data: { userId: string }) =>
		http.post(`${apiEndpoint}`, data, {
			headers: { "Content-Type": "application/json", ...getAuthHeader() },
		}),
	sendOfflineMessageNotificationToCustomer: (data: { userId: string }) =>
		http.post(`${apiEndpoint}/offline-messages-notification`, data, {
			headers: { "Content-Type": "application/json", ...getAuthHeader() },
		}),
};

// =====|  APIs  |=====

export const sendWelcomeEmailToCustomer = (data: { userId: string }) => {
	return emailNotifications.sendWelcomeEmailToCustomer(data);
};
export const sendOfflineMessageNotificationToCustomer = (data: { userId: string }) => {
	return emailNotifications.sendOfflineMessageNotificationToCustomer(data);
};
